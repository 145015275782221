import React from "react"
import { Link } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <main>
      <span role="img" aria-label="Oh no!">🤷‍♂️</span>
      <p>You just hit a route that doesn&#39;t exist...</p>
      <p><Link title={`Return to home`} to={`/`} >Return to home</Link></p>
    </main>
  </Layout>
)

export default NotFoundPage
